import React from "react";
import { withRouter } from "react-router";
import { Redirect, Link } from "react-router-dom";
import firebase from "firebase/app";
import "firebase/functions";



class AddAnswer extends React.Component {

  constructor(props) {
    super(props);

    this.state = {text: ''};

  }

  handleChange = (event) => {
    this.setState({text: event.target.value});
  }

  handleSubmit = (event) => {

    firebase.app().functions().httpsCallable("apiAddAnswer")({
      questionId: this.props.match.params.questionId,
      answerId: this.props.match.params.answerId,
      text: this.state.text,
      videoUrl: "test video url",
      audioUrl: "test audio url",
      thumbnailUrl: "test thumbnailUrl url",
    }).then(result => {
      this.setState({ redirect: "/question/" + this.props.match.params.questionId});
    });

    event.preventDefault();
  }

  componentDidMount() {
    console.log("!!!2", this.props);
  }


  render() {

    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />
    }

    return (
        <div>
          <h2><Link to={"/"}>Главная</Link> / Новый ответ на вопрос</h2>
          <form onSubmit={this.handleSubmit}>
            <label>
              Текст ответа:
              <input type="text" value={this.state.value} onChange={this.handleChange} />
            </label>
            <input type="submit" className={"button button5"} value="Отправить" />
          </form>
        </div>
    );
  }
}

export default withRouter(AddAnswer);