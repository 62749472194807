import React from "react";
import { withRouter } from "react-router";
import { Link, Redirect } from "react-router-dom";
import firebase from "firebase/app";
import "firebase/functions";

class User extends React.Component {

  constructor(props) {
    super(props);

    console.log("!!!1", this.props);

    this.state = {
      user: null,
      questions: [],
      answers: [],
      currentQuestionPageId: null,
      currentAnswerPageId: null
    };
  }

  componentDidMount() {
    this.updateData();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    console.log(1);
    if(this.props.match.params.id !== prevProps.match.params.id) {
      console.log(2);
      this.updateData();
    }
  }

  updateData(clear = false) {

    this.id = this.props.match.params.id;

    firebase.app().functions().httpsCallable("apiGetUser")({userId: this.id}).then(userResult => {
      //console.log(channelResult);
      this.setState({user: userResult.data})
      this.updateQuestions(clear);
      this.updateAnswers(clear);
    }).catch(error => {
      console.log(error);
    });
  }

  updateQuestions(clear) {
    let params = {filterBy: {userIds: [this.id]}};
    firebase.app().functions().httpsCallable("apiGetQuestions")(params).then(result => {
      let q = result.data;
      this.setState({questions: clear ? q : this.state.questions.concat(q)});
    }).catch(error => {
      console.log(error);
    });
  }

  updateAnswers(clear) {
    firebase.app().functions().httpsCallable("apiGetAnswers")({
      filterBy: {
        userId: this.id
      }
    }).then(result => {
      let a = result.data;
      this.setState({answers: clear ? a : this.state.answers.concat(a)});
    }).catch(error => {
      console.log(error);
    });
  }

  clickRemoveChannel = (channelId) => {
    firebase.app().functions().httpsCallable("apiRemoveChannelFromUser")({
      userId: this.id,
      channelId: channelId
    }).then(result => {
      console.log(result);
      this.updateData(true);
    }).catch(error => {
      console.log(error);
    });
    return false;
  }

  clickRemoveQuestion = (index) => {
    const question = this.state.questions[index];
    firebase.app().functions().httpsCallable("apiRemoveQuestion")({
      questionId: question.id
    }).then(result => {
      console.log(result);
      this.updateData(true);
    }).catch(error => {
      console.log(error);
    });
    return false;
  }

  clickRemoveAnswer = (index) => {
    const answer = this.state.answers[index];
    firebase.app().functions().httpsCallable("apiRemoveAnswer")({
      answerId: answer.id,
    }).then(result => {
      this.updateData(true);
    }).catch(error => {
      console.log(error);
    });
    return false;
  }

  clickSubscribe = (userId) => {
    firebase.app().functions().httpsCallable("apiSubscribeToUser")({
      userId: userId,
    }).then(result => {
      //this.updateData(true);
    }).catch(error => {
      console.log(error);
    });
    return false;
  }

  clickUnsubscribe = (userId) => {
    firebase.app().functions().httpsCallable("apiUnsubscribeFromUser")({
      userId: userId,
    }).then(result => {
      //this.updateData(true);
    }).catch(error => {
      console.log(error);
    });
    return false;
  }


  render() {

    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />
    }

    return (
        <div>
          <h2><Link to={"/"}>Главная</Link> / {!!this.state.user && this.state.user.userName}</h2>
          <h3>Каналы юзера, {!!this.state.user && !!this.state.user.channels && this.state.user.channels.length} штук:</h3>
          <ul>{!!this.state.user && !!this.state.user.channels && this.state.user.channels.map((channelId, index) => {
            return <li key={channelId}>
              <Link to={"/channel/" + channelId}>{channelId}</Link>
              <a href={"#"} onClick={() => this.clickRemoveChannel(channelId)} className={"button button3 small"}>удалить</a></li>
          })}
          </ul>
          <h3>Вопросы автора, {!!this.state.user && this.state.user.countQuestions} штук:</h3>
          <ul>{this.state.questions.map((question, index) => {
            return <li key={question.id}>
              <Link to={"/question/" + question.id}>{question.text}</Link>
              (Вопрос в канале <Link to={"/channel/" + question.channelId}>{question.channelId}</Link> {!!question.countAnswers && ", " + question.countAnswers + " ответов"})
              <a href={"#"} onClick={() => this.clickRemoveQuestion(index)} className={"button button3 small"}>удалить</a></li>
          })}
          </ul>
          <h3>Ответы автора, {!!this.state.user && this.state.user.countAnswers} штук:</h3>
          <ul>{this.state.answers.map((answer, index) => {
            return <li key={answer.id}>{answer.text} (<Link to={"/question/" + answer.question.questionId}>Смотреть вопрос</Link>) <a href={"#"} onClick={() => this.clickRemoveAnswer(index)} className={"button button3 small"}>удалить</a></li>
          })}
          </ul>
          <a href={"#"} onClick={() => {this.clickSubscribe(this.state.user.id)}}>Подписаться</a>
          <a href={"#"} onClick={() => {this.clickUnsubscribe(this.state.user.id)}}>Отписаться</a>
        </div>
    );
  }
}

export default withRouter(User);
