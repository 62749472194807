import React from "react";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import firebase from "firebase/app";
import "firebase/functions";


class Answer extends React.Component {

  constructor(props) {
    super(props);

    console.log("!!!1", this.props);

    this.state = {answers: []};
  }

  updateData() {
    console.log("!!!2", this.props);

    this.id = this.props.match.params.id;


    firebase.app().functions().httpsCallable("apiGetAnswers")({
      filterBy: {
        answerId: this.id
      }
    }).then(result => {
      this.setState({answers: result.data})
    }).catch(error => {
      console.log(error);
    });
  }

  componentDidMount() {
    this.updateData();
  }

  onLikeDislikeAnswer = (answerId, like) => {
    firebase.app().functions().httpsCallable("apiLikeDislikeAnswer")({answerId: answerId, like: like}).then(answerResult => {
      this.updateData();
    }).catch(error => {
      console.log(error);
    });
  }


  render() {

    if(this.state.answers.length === 0)  return null;

    const answers = this.state.answers;

    return (
        <div>
          <h2>
            <Link to={"/"}>Главная</Link> {" / "}
            <Link to={"/question/" + answers[0].question.questionId}>{answers[0].question.questionId}</Link>
            {/*{" / " + this.state.question.text}*/}
          </h2>
          <h3>Реплаи:</h3>
          <ul>{!!answers && answers.map(answer => {
            return <li>{answer.text} (Ответ от <Link to={"/user/"+answer.author.userId}>{answer.author.userName}</Link>) {answer.countReplies > 0 ? ' - ' + answer.countReplies + ' реплаев' : ''}{answer.countLikes > 0 ? ' - ' + answer.countLikes + ' лайков' : ''}
              <a href={"#"} onClick={() => this.onLikeDislikeAnswer(answer.id, true)} className={"button button5"}>Like</a>
              <a href={"#"} onClick={() => this.onLikeDislikeAnswer(answer.id, false)} className={"button button5"}>Dislike</a>
            </li>
          })}
          </ul>

        </div>
    );
  }
}

export default withRouter(Answer);
