import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useRouteMatch,
  useParams
} from "react-router-dom";

import firebase from "firebase/app";
import "firebase/functions";

import Home from "./components/Home";
import Channel from "./components/Channel";
import Question from "./components/Question";
import User from "./components/User";
import ChannelsList from "./components/ChannelsList";
import AddQuestion from "./components/AddQuestion";
import AddAnswer from "./components/AddAnswer";
import {USE_EMULATOR} from "./common/const";
import Profile from "./components/Profile";
import Answer from "./components/Answer";

firebase.initializeApp({
  apiKey: process.env.REACT_APP_apiKey,
  authDomain: process.env.REACT_APP_authDomain,
  projectId: process.env.REACT_APP_projectId,
  storageBucket: process.env.REACT_APP_storageBucket,
  messagingSenderId: process.env.REACT_APP_messagingSenderId,
  appId: process.env.REACT_APP_appId
});

if(process.env.REACT_APP_useEmulator === "true") {
  firebase.functions().useFunctionsEmulator('http://localhost:5001');
}

class App extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      authedUser: null
    }

  }

  componentDidMount() {
    let firebaseui = require('firebaseui');
    this.ui = new firebaseui.auth.AuthUI(firebase.auth());

    firebase.auth().onAuthStateChanged((user) => {
      console.log("onAuthStateChanged", user);
      if (user) {

        firebase.app().functions().httpsCallable("apiGetUser")()
            .then(result => {
              this.setState({authedUser: result.data});
            })
            .catch(error => {
              console.log(error);
            })
      } else {
      }
    });
  }

  onAuthClick = () => {

    this.ui.start('#firebaseui-auth-container', {
      signInOptions: [
        // List of OAuth providers supported.
        firebase.auth.GoogleAuthProvider.PROVIDER_ID
      ],
      // Other config options...
    });
  }


  render() {

    return (
      <Router>
        <div>
          <div id={"firebaseui-auth-container"}></div>
          {!this.state.authedUser && <a href={"#"} onClick={this.onAuthClick} className={"button button1"}>Авторизация</a>}
          {this.state.authedUser && <Link to={"/profile"}>Профиль</Link>}
        </div>
        <div>

          <Switch>

            <Route path={`/user/:id`}>
              <User />
            </Route>

            <Route path={`/question/:id`}>
              <Question />
            </Route>

            <Route path={`/answer/:id`}>
              <Answer />
            </Route>

            <Route path={`/channel/:id`}>
              <Channel user={this.state.authedUser}/>
            </Route>

            <Route path={`/add-question/:channelId`}>
              <AddQuestion user={this.state.authedUser}/>
            </Route>

            <Route path={`/add-answer/:questionId/:answerId?`}>
              <AddAnswer user={this.state.authedUser}/>
            </Route>

            <Route path={`/profile`}>
              <Profile user={this.state.authedUser}/>
            </Route>

            <Route path="/">
              <Home />
            </Route>
          </Switch>
        </div>
      </Router>
    );
  }
}

export default App;
