import React from "react";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import firebase from "firebase/app";
import "firebase/functions";


class Channel extends React.Component {

  constructor(props) {
    super(props);

    console.log("!!!1", this.props);

    this.state = {channel: null, questions: [], currentPageId: null, nextPageId: null};
  }

  async componentDidMount() {
    await this.updateChannel();
    await this.updateQuestions();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    /*if(this.props.match.params.id !== prevProps.match.params.id) {
      this.updateQuestions();
    }*/
  }

  clickSubscribe = () => {
    firebase.app().functions().httpsCallable("apiSubscribeToChannels")({
      channelIds: [this.id]
    }).then(result => {
      console.log(result);
      //this.updateData(true);
    }).catch(error => {
      console.log(error);
    });
    return false;
  }

  clickUnsubscribe = () => {
    firebase.app().functions().httpsCallable("apiUnsubscribeFromChannels")({
      channelIds: [this.id]
    }).then(result => {
      console.log(result);
      //this.updateData(true);
    }).catch(error => {
      console.log(error);
    });
    return false;
  }

  updateChannel() {
    this.id = this.props.match.params.id;
    return firebase.app().functions().httpsCallable("apiGetChannel")({channelId: this.id}).then(channelResult => {
      this.setState({
        channel: channelResult.data
      })
    }).catch(error => {
      console.log(error);
    });
  }



  updateQuestions() {

    let params = {
      filterBy: {
        channelIds: [this.id]
      },
      orderBy: 'updatedTime'
    }
    if(this.state.questions.length) {
      //params.lastId = this.state.questions[this.state.questions.length-1].id;
      params.startAfterId = this.state.questions[this.state.questions.length-1].id;
    }
    return firebase.app().functions().httpsCallable("apiGetQuestions")(params).then(questionsPageResult => {
      this.setState({questions: this.state.questions.concat(questionsPageResult.data)});
    }).catch(error => {
      console.log(error);
    });
  }

  onClickMoreQuestions = () => {
    this.updateQuestions();
  }


  render() {

    return (
        <div>
          <h2><Link to={"/"}>Главная</Link> / {!!this.state.channel && this.state.channel.title}</h2>
          <h3>Вопросы канала:</h3>
          <ul>{this.state.questions.map(question => {
            return <li key={question.id}><Link to={"/question/" + question.id}>{question.text}</Link> (Вопрос от <Link to={"/user/"+question.author.userId}>{question.author.userName}</Link>{!!question.countAnswers && ", " + question.countAnswers + " ответов"}{!!question.countLikes && ", " + question.countLikes + " лайков"})</li>
          })}
          </ul>
          {!!this.state.questions.length && <a class={"button button2 small"} href="#" onClick={this.onClickMoreQuestions}>Еще вопросы</a>}
          <br /><br />
          {!!this.state.channel && <Link to={"/add-question/"+this.state.channel.id} class={"button button1"}>Добавить вопрос в канал</Link>}
          {!!this.state.channel && <a href={"#"} onClick={this.clickSubscribe} class={"button button1"}>Подписаться</a>}
          {!!this.state.channel && <a href={"#"} onClick={this.clickUnsubscribe} class={"button button1"}>Отписаться</a>}

        </div>
    );
  }
}

export default withRouter(Channel);
