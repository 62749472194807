import React from "react";
import { withRouter } from "react-router";
import { Link, Redirect } from "react-router-dom";
import firebase from "firebase/app";
import "firebase/functions";

class Profile extends React.Component {

  constructor(props) {
    super(props);

    console.log("!!!1", this.props);

    this.state = {
      fields: {
        userName: "",
        firstName: "",
        lastName: "",
        profileColor: "",
        profileImageUrl: "",
        profileVideoUrl: "",
        userImageUrl: ""
      }
    };

  }

  componentDidMount() {
    if(!this.props.user) {
      this.setState({redirect: "/"});
      return;
    }

    console.log(this.props.user);
    let fields = Object.assign({}, this.state.fields);
    Object.keys(fields).forEach(field => {fields[field]=this.props.user[field]});
    this.setState({fields: fields});


  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    /*let fields = Object.assign({}, this.state.fields);

    Object.keys(fields).forEach(field => {fields[field]=this.props.user[field]});

    this.setState({fields: fields});

    console.log(fields);*/
  }

  updateData(clear = false) {

    /*firebase.app().functions().httpsCallable("apiGetUser")().then(userResult => {
      console.log(userResult);
      this.setState({user: userResult.data})
    }).catch(error => {
      console.log(error);
    });*/
  }

  handleChange = (event) => {

    let fields = Object.assign({}, this.state.fields);
    fields[event.target.name] = event.target.value;
    console.log(event.target.name, event.target.value, fields);
    this.setState({fields: fields});
  }

  handleSubmit = (event) => {

    firebase.app().functions().httpsCallable("apiUpdateUser")(this.state.fields).then(result => {
      //this.setState({ redirect: "/channel/" + this.props.match.params.channelId});
    });

    event.preventDefault();
  }



  render() {

    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />
    }

    if(!this.props.user) return null;

    return (
        <div>
          <h2><Link to={"/"}>Главная</Link> / {!!this.props.user && this.props.user.id}</h2>

          <form onSubmit={this.handleSubmit}>

            <label>
              userName<input type="text" value={this.state.fields.userName} name={"userName"} onChange={this.handleChange} />
            </label>
            <br/>
            <label>
              firstName<input type="text" value={this.state.fields.firstName} name={"firstName"} onChange={this.handleChange} />
            </label>
            <br/>
            <label>
              lastName<input type="text" value={this.state.fields.lastName} name={"lastName"} onChange={this.handleChange} />
            </label>
            <br/>
            <label>
              profileColor<input type="text" value={this.state.fields.profileColor} name={"profileColor"} onChange={this.handleChange} />
            </label>
            <br/>
            <label>
              profileImageUrl<input type="text" value={this.state.fields.profileImageUrl} name={"profileImageUrl"} onChange={this.handleChange} />
            </label>
            <br/>
            <label>
              profileVideoUrl<input type="text" value={this.state.fields.profileVideoUrl} name={"profileVideoUrl"} onChange={this.handleChange} />
            </label>
            <br/>
            <label>
              userImageUrl<input type="text" value={this.state.fields.userImageUrl} name={"userImageUrl"} onChange={this.handleChange} />
            </label>
            <br/>
            <input type="submit" className={"button button5"} value="Отправить" />
          </form>

        </div>
    );
  }
}

export default withRouter(Profile);